import 'Shared/sass/widgets/WidgetContainer.scss'

import React, { FunctionComponent } from 'react'

// import Slide from 'react-awesome-reveal'
import PuxAnchor from '../PuxAnchor/PuxAnchor'
import { WidgetMetadata, WidgetProperitesPart } from './WidgetBuilder'

interface WidgetContainerProps extends WidgetProperitesPart, WidgetMetadata {
  children: React.ReactNode
  fullWidth?: boolean
  thin?: boolean
  cropped?: boolean
}

interface WidgetAttributes {
  className: string
  id?: string
}

const rootClass = `widget-container`

const WidgetContainer: FunctionComponent<WidgetContainerProps> = (props) => {
  let className = rootClass

  className += props.fullWidth ? `` : ` ${rootClass}-padding`

  className += props.thin ? ` ${rootClass}-thin` : ``

  className += props.cropped ? ` ${rootClass}-cropped` : ``

  props.widgetPropertiesPartMarginBottom
    ? (className += ` ${props.widgetPropertiesPartMarginBottom}`)
    : null
  props.widgetPropertiesPartMarginTop
    ? (className += ` ${props.widgetPropertiesPartMarginTop}`)
    : null
  props.widgetPropertiesPartPaddingBottom
    ? (className += ` ${props.widgetPropertiesPartPaddingBottom}`)
    : null
  props.widgetPropertiesPartPaddingTop
    ? (className += ` ${props.widgetPropertiesPartPaddingTop}`)
    : null
  props.widgetPropertiesPartMobileOrder
    ? (className += ` mobile-order-${props.widgetPropertiesPartMobileOrder}`)
    : null
  props.size ? (className += ` w-${props.size}`) : null
  props.alignment ? (className += ` ${props.alignment}`) : null

  const widgetAttributes: WidgetAttributes = {
    className: className,
  }

  // props.widgetPropertiesPartID
  //   ? (widgetAttributes.id = props.widgetPropertiesPartID)
  //   : null

  if (!props.widgetPropertiesPartID) {
    return (
      <div {...widgetAttributes}>
        {/* <Slide direction='left' fraction={0} triggerOnce duration={500}> */}
        {props.children}
        {/* </Slide> */}
      </div>
    )
  } else if (props.widgetPropertiesPartID === `remove-position-relative`) {
    return (
      <div {...widgetAttributes} id={props.widgetPropertiesPartID}>
        {props.children}
      </div>
    )
  } else {
    return (
      <div {...widgetAttributes}>
        <PuxAnchor id={props.widgetPropertiesPartID} />
        {props.children}
      </div>
    )
  }
}

export default WidgetContainer
