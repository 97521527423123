import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import PuxTopImage, { PuxTopImageType } from '../PuxTopImage/PuxTopImage'

export interface WidgetPuxTopImageType extends WidgetBase, PuxTopImageType {
  contentType: 'PuxTopImage'
}

const WidgetPuxTopImage: FunctionComponent<WidgetPuxTopImageType> = (props) => (
  <WidgetContainer {...props.puxWidgetProperties} {...props.metadata} fullWidth>
    <PuxTopImage
      displayText={props.displayText}
      topImageBackground={props.topImageBackground}
      puxVideo={props.puxVideo}
      topImageBenefit1={props.topImageBenefit1}
      topImageBenefit2={props.topImageBenefit2}
      topImageButton={props.topImageButton}
      topImageSecondaryButton={props.topImageSecondaryButton}
      topImageContent={props.topImageContent}
      topImageContentAlign={props.topImageContentAlign}
      topImageContentColor={props.topImageContentColor}
      topImageButtonAlign={props.topImageButtonAlign}
      topImageImageStyle={props.topImageImageStyle}
    />
  </WidgetContainer>
)

export default WidgetPuxTopImage
